import { Injectable } from '@angular/core';
import { fromEvent, map, merge, Observable, startWith, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private inactivityTime = 15 * 60 * 1000; // 15 minutes

  startMonitoring(): Observable<boolean> {
    const mouseMove$ = fromEvent(document, 'mousemove').pipe(map(() => true));
    const click$ = fromEvent(document, 'click').pipe(map(() => true));
    const keydown$ = fromEvent(document, 'keydown').pipe(map(() => true));
    const touchstart$ = fromEvent(document, 'touchstart').pipe(map(() => true));
    const touchmove$ = fromEvent(document, 'touchmove').pipe(map(() => true));

    return merge(mouseMove$, click$, keydown$, touchstart$, touchmove$).pipe(
      startWith(true),
      switchMap(() => timer(this.inactivityTime).pipe(map(() => false)))
    );
  }
}
