import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { OfferCardComponent } from '../offer-card/offer-card.component';
import { Offer } from '../../../features/offers/models/offer.interface';
import { animationDuration } from '../../../core/constants/data';

@Component({
  selector: 'app-mobile-carousel',
  standalone: true,
  imports: [CommonModule, OfferCardComponent],
  templateUrl: './mobile-carousel.component.html',
  styleUrls: ['../../../../../node_modules/keen-slider/keen-slider.min.css', './mobile-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileCarouselComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) offers!: Offer[];
  @Input() enableLoop!: boolean;
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  slider: KeenSliderInstance | null = null;

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: this.enableLoop,
      mode: 'free',
      slides: {
        perView: 1.1,
        spacing: 0,
        origin: this.enableLoop ? 0.5 : 0,
      },
    });
  }

  ngOnDestroy() {
    setTimeout(() => {
      if (this.slider) this.slider.destroy();
    }, animationDuration);
  }
}
