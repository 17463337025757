import { Injectable } from '@angular/core';
import { ApiToken } from '../models/api-token.interface';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  getToken(): string | undefined {
    return window.localStorage['authToken'];
  }

  getTokenAndExp(): ApiToken {
    return {
      token: window.localStorage['authToken'],
      expiry: window.localStorage['tokenExpiry'],
    };
  }

  isTokenValid(): boolean {
    const token = window.localStorage['authToken'];
    const exp = window.localStorage['tokenExpiry'];
    if (!token || !exp) {
      return false;
    }
    const now = new Date();
    return new Date(exp) > now;
  }

  saveTokenAndExp(apiToken: ApiToken): void {
    window.localStorage['authToken'] = apiToken.token;
    window.localStorage['tokenExpiry'] = apiToken.expiry;
  }

  destroyToken(): void {
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('tokenExpiry');
  }
}
