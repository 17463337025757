import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferCardComponent } from '../../../../shared/components/offer-card/offer-card.component';
import { Offer } from '../../../offers/models/offer.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { MobileCarouselComponent } from '../../../../shared/components/mobile-carousel/mobile-carousel.component';

@Component({
  selector: 'app-demo-offers',
  standalone: true,
  imports: [CommonModule, OfferCardComponent, MobileCarouselComponent],
  templateUrl: './demo-offers.component.html',
  styleUrls: ['./demo-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoOffersComponent {
  @Input() offers!: Offer[];
  isXSmallScreen$ = this.breakpointObserver.observe(Breakpoints.XSmall).pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver) {}
}
