import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, finalize, Subscription, take } from 'rxjs';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CartStoreService } from '../../features/cart/cart-store.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private initializedSub = new BehaviorSubject<boolean>(false);
  public initialized$ = this.initializedSub.asObservable();
  private navSubscription!: Subscription;
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private cartService: CartStoreService
  ) {}

  private loginWithUrlToken(token: string) {
    this.authService
      .login(token)
      .pipe(
        finalize(() => {
          this.initFinish();
        })
      )
      .subscribe({
        next: () => {
          this.cartService.initCartFromLocalStorage();
          this.router.navigate(['/offers']);
        },
        error: err => {
          console.log(err);
        },
      });
  }

  private checkAuthCookies() {
    const isAuth: boolean = this.tokenService.isTokenValid();
    if (isAuth) {
      this.authService.setAuth(this.tokenService.getTokenAndExp());
      this.cartService.initCartFromLocalStorage();
    }
    this.initFinish();
  }

  private initFinish() {
    this.navSubscription.unsubscribe();
    this.initializedSub.next(true);
  }

  public initializeApp(): void {
    this.navSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        const userTokenFromUrl = this.activatedRoute.snapshot.queryParamMap.get('userToken');

        this.router.navigate([], {
          skipLocationChange: true,
          queryParams: { userToken: null },
          queryParamsHandling: 'merge',
        });
        return userTokenFromUrl ? this.loginWithUrlToken(userTokenFromUrl) : this.checkAuthCookies();
      });
  }
}
