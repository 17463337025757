import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowToUseComponent } from './components/how-to-use/how-to-use.component';
import { DemoOffersComponent } from './components/demo-offers/demo-offers.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ContactComponent } from './components/contact/contact.component';
import { OfferService } from '../offers/services/offer.service';
import { Offer } from '../offers/models/offer.interface';
import { Observable, Subscription } from 'rxjs';
import { UtilNavigationService } from '../../core/services/util-navigation.service';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule, HowToUseComponent, DemoOffersComponent, PartnersComponent, ContactComponent],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements AfterViewInit, OnDestroy {
  offers$: Observable<Offer[]> = this.offerService.getLandingPageOffers();
  anchorSub!: Subscription;
  constructor(
    private offerService: OfferService,
    private utilNavigationService: UtilNavigationService
  ) {}

  ngAfterViewInit() {
    this.anchorSub = this.utilNavigationService.captureFragmentAndScroll().subscribe();
  }

  ngOnDestroy(): void {
    this.anchorSub.unsubscribe();
  }
}
