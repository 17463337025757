import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { UtilNavigationService } from '../../services/util-navigation.service';

@Component({
  selector: 'app-layout-footer',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor(private utilNavigationService: UtilNavigationService) {}
  navigateToRegulations(targetPath: string) {
    this.utilNavigationService.navigateToPageThatContainsFragment(targetPath, 'menu');
  }
}
