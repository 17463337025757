<div (click)="goToDetails(offer.id)" class="card" [ngClass]="{ 'filter-gray': !offer.active }">
  <div class="card__image" [style.background-image]="'url(' + offer.offer_image + ')'">
    <div class="card__badge" [ngClass]="{ voucher: offer.offer_type === 'paid' }">
      <span
        [ngClass]="{
          small: offer.discount_description.length <= 8,
          medium: offer.discount_description.length <= 7,
          large: offer.discount_description.length <= 6
        }">
        {{ offer.discount_description }}
      </span>
    </div>
    <div class="card__brand util__image-container">
      <img [src]="offer.brand_image" alt="" />
    </div>
  </div>
</div>
