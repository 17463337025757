import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appScrollFixNavbar]',
  standalone: true,
})
export class ScrollFixNavbarDirective {
  private scrollThreshold = 12;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private hostElement: ElementRef
  ) {}

  @HostListener('document:scroll') onScroll(): void {
    const scrollPosition = window.scrollY || this.document.documentElement.scrollTop || this.document.body.scrollTop;

    if (scrollPosition > this.scrollThreshold) {
      this.renderer.addClass(this.hostElement.nativeElement, 'fixed-navbar');
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, 'fixed-navbar');
    }
  }
}
