<div class="landing-page">
  <section id="how-to-use">
    <app-how-to-use></app-how-to-use>
  </section>
  <ng-container *ngIf="offers$ | async as offers">
    <section *ngIf="offers.length" id="offers">
      <app-demo-offers [offers]="offers"></app-demo-offers>
    </section>
  </ng-container>
  <section id="partners">
    <app-partners></app-partners>
  </section>
  <section id="contact">
    <app-contact></app-contact>
  </section>
</div>
