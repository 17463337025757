<h2 class="title">Nasi Partnerzy</h2>
<div class="keen-slider" #sliderRef>
  <ng-container *ngIf="isXSmallScreen$ | async">
    <div class="keen-slider__slide number-slide" *ngFor="let slide of slidesMobile">
      <div class="logo" *ngFor="let logo of slide.logos">
        <img src="assets/images/brands/partners/{{ logo.src }}" alt="" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isSmallScreen$ | async">
    <div class="keen-slider__slide number-slide" *ngFor="let slide of slidesTablet">
      <div class="logo" *ngFor="let logo of slide.logos">
        <img src="assets/images/brands/partners/{{ logo.src }}" alt="" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(isXSmallScreen$ | async) === false && (isSmallScreen$ | async) === false">
    <div class="keen-slider__slide number-slide" *ngFor="let slide of slidesDesktop">
      <div class="logo" *ngFor="let logo of slide.logos">
        <img src="assets/images/brands/partners/{{ logo.src }}" alt="" />
      </div>
    </div>
  </ng-container>
</div>
