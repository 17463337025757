import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly tokenService: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const reqOrigin = new URL(request.url).origin;
    const apiOrigin = new URL(environment.api).origin;

    if (reqOrigin === apiOrigin && this.tokenService.isTokenValid()) {
      const token = this.tokenService.getToken();
      const apiReq = request.clone({
        setHeaders: {
          ...(token ? { Authorization: `Token ${token}` } : {}),
        },
      });
      return next.handle(apiReq);
    } else {
      return next.handle(request);
    }
  }
}
