import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { UtilNavigationService } from '../../services/util-navigation.service';
import { CartStoreService } from '../../../features/cart/cart-store.service';
import { AuthService } from '../../services/auth.service';
import { HeaderService } from './header.service';
import { AdminBanner } from './header.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ScrollFixNavbarDirective } from '../../../shared/directives/scroll-fix-navbar.directive';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-layout-header',
  standalone: true,
  imports: [CommonModule, RouterLink, MobileNavComponent, ScrollFixNavbarDirective],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  isAuthenticated!: boolean;
  totalCartQuantity$ = this.cartStoreService.getTotalQuantity();
  adminBanner!: AdminBanner;
  isXSmallScreen!: boolean;
  private breakpointSubscription!: Subscription;
  constructor(
    private router: Router,
    private utilNavigationService: UtilNavigationService,
    private cartStoreService: CartStoreService,
    private authService: AuthService,
    private headerService: HeaderService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated.pipe(takeUntil(this.destroy$)).subscribe(boolean => {
      this.isAuthenticated = boolean;
      this.loadAdminBanner();
    });

    this.breakpointSubscription = this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(result => {
      this.isXSmallScreen = result.matches;
    });
  }

  loadAdminBanner() {
    this.headerService.getAdminBanner().subscribe(data => {
      this.adminBanner = data;
    });
  }
  setAdminBanner(): string | void {
    if (this.adminBanner) {
      const path = this.isXSmallScreen ? this.adminBanner.image_mobile : this.adminBanner.image_desktop;
      return `url(${path})`;
    }
  }

  navigate(targetPath: string, anchor?: string) {
    this.utilNavigationService.navigateToPageThatContainsFragment(targetPath, anchor);
  }

  login() {
    window.location.href = `${environment.externalAuthUrl}`;
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.breakpointSubscription.unsubscribe();
  }
}
