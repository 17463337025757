<nav class="mobile-menu">
  <div class="navbar">
    <img
      class="logo"
      routerLink="/"
      [ngStyle]="{ visibility: isNavigationOpen ? 'hidden' : 'visible' }"
      src="/assets/images/brands/santander_logo_red.svg"
      alt="" />
    <div (click)="toggleNav()" class="hamburger-btn">
      <div class="icon-bar" [@hamburguerX]="!isNavigationOpen ? 'hamburguer' : 'topX'"></div>
      <div class="icon-bar" [@hamburguerX]="!isNavigationOpen ? 'hamburguer' : 'hide'"></div>
      <div class="icon-bar" [@hamburguerX]="!isNavigationOpen ? 'hamburguer' : 'bottomX'"></div>
    </div>
  </div>
  <div class="toggle-list" [ngClass]="isNavigationOpen ? 'opened-nav' : 'closed-nav'">
    <ul *ngIf="isNavigationOpen">
      <li><a class="link" (click)="navigate('welcome', 'how-to-use')">Jak to działa?</a></li>
      <li><a class="link" (click)="navigate('welcome', 'partners')">Nasi partnerzy</a></li>
      <ng-container *ngIf="isAuthenticated | async; else publicNav">
        <li><a class="link" (click)="navigate('order-history')">Historia zamówień</a></li>
        <li>
          <ng-container *ngIf="totalCartQuantity$ | async as total; else noItem">
            <button (click)="navigate('cart')" class="button primary">Twój koszyk: {{ total }}</button>
          </ng-container>
          <ng-template #noItem>
            <button (click)="navigate('cart')" class="button">Twój koszyk</button>
          </ng-template>
        </li>
        <li>
          <button (click)="logout()" class="button primary">Wyloguj się</button>
        </li>
      </ng-container>
      <ng-template #publicNav>
        <li>
          <button (click)="login()" class="button primary">Zaloguj się</button>
        </li>
      </ng-template>
    </ul>
  </div>
</nav>
