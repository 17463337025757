import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../environments/environment';

export const authGuard: CanActivateFn = () => {
  const isAuthenticated = inject(AuthService).isAuthenticated;

  return isAuthenticated.pipe(
    map(isAuthenticated => {
      if (isAuthenticated) {
        return true;
      } else {
        window.location.href = `${environment.externalAuthUrl}`;
        return false;
      }
    })
  );
};
