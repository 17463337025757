import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { hamburguerXAnimation } from './mobile-nav.animations';
import { RouterLink } from '@angular/router';
import { UtilNavigationService } from '../../../../services/util-navigation.service';
import { CartStoreService } from '../../../../../features/cart/cart-store.service';
import { AuthService } from '../../../../services/auth.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-mobile-nav',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
  animations: [hamburguerXAnimation],
})
export class MobileNavComponent {
  isNavigationOpen = false;
  totalCartQuantity$ = this.cartStoreService.getTotalQuantity();
  isAuthenticated = this.authService.isAuthenticated;

  constructor(
    private utilNavigationService: UtilNavigationService,
    private cartStoreService: CartStoreService,
    private authService: AuthService
  ) {}

  toggleNav() {
    this.isNavigationOpen = !this.isNavigationOpen;
    if (this.isNavigationOpen) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }

  navigate(targetPath: string, anchor?: string) {
    this.isNavigationOpen = false;
    document.body.classList.remove('disable-scroll');
    setTimeout(() => {
      this.utilNavigationService.navigateToPageThatContainsFragment(targetPath, anchor);
    }, 50);
  }

  login() {
    this.toggleNav();
    window.location.href = `${environment.externalAuthUrl}`;
  }

  logout() {
    this.authService.logout();
    this.toggleNav();
  }
}
