import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PartnersService {
  getDesktopSlides() {
    return [
      {
        logos: [
          { src: '4F_Logo.png' },
          { src: 'Answear_Logo.png' },
          { src: 'BodyChief_Logo.png' },
          { src: 'Bonito_Logo.png' },
          { src: 'Chocholowskie_termy_Logo.png' },
          { src: 'CostaCoffee_Logo.png' },
          { src: 'Dajar_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'Duka_Logo.png' },
          { src: 'Empik_Logo.png' },
          { src: 'FlixBus_Logo.png' },
          { src: 'Frisco_Logo.png' },
          { src: 'Gerlach_logo_Logo.png' },
          { src: 'Hebe_Logo.png' },
          { src: 'LegalneLody_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'Lerni_Logo.png' },
          { src: 'L_Occitane_Logo.png' },
          { src: 'Martes_Sport_Logo.png' },
          { src: 'Medicine_Logo.png' },
          { src: 'Mojcatering_Logo.png' },
          { src: 'NewBalance_Logo.png' },
          { src: 'NTFY_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'Onet_Premium_Logo.png' },
          { src: 'SaladStory_Logo.png' },
          { src: 'Sephora_Logo.png' },
          { src: 'Smyk_Logo.png' },
          { src: 'Sphinx_Logo.png' },
          { src: 'Sportroom_Logo.png' },
          { src: 'Sun&Snow_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'Suntago_Logo.png' },
          { src: 'SuperMenu_Logo.png' },
          { src: 'TaniaKsiazka_Logo.png' },
          { src: 'ThaiWok_Logo.png' },
          { src: 'Woblink_Logo.png' },
          { src: 'Wolt_Logo.png' },
          { src: 'Wyjotkowy_prezent_Logo.png' },
          { src: 'Zatorland_Logo.png' },
        ],
      },
    ];
  }
  getTabletSlides() {
    return [
      {
        logos: [
          { src: '4F_Logo.png' },
          { src: 'Answear_Logo.png' },
          { src: 'BodyChief_Logo.png' },
          { src: 'Bonito_Logo.png' },
          { src: 'Chocholowskie_termy_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'CostaCoffee_Logo.png' },
          { src: 'Dajar_Logo.png' },
          { src: 'Duka_Logo.png' },
          { src: 'Empik_Logo.png' },
          { src: 'FlixBus_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'Frisco_Logo.png' },
          { src: 'Gerlach_logo_Logo.png' },
          { src: 'Hebe_Logo.png' },
          { src: 'LegalneLody_Logo.png' },
          { src: 'Lerni_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'L_Occitane_Logo.png' },
          { src: 'Martes_Sport_Logo.png' },
          { src: 'Medicine_Logo.png' },
          { src: 'Mojcatering_Logo.png' },
          { src: 'NewBalance_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'NTFY_Logo.png' },
          { src: 'Onet_Premium_Logo.png' },
          { src: 'SaladStory_Logo.png' },
          { src: 'Sephora_Logo.png' },
          { src: 'Smyk_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'Sphinx_Logo.png' },
          { src: 'Sportroom_Logo.png' },
          { src: 'Sun&Snow_Logo.png' },
          { src: 'Suntago_Logo.png' },
          { src: 'SuperMenu_Logo.png' },
        ],
      },
      {
        logos: [
          { src: 'TaniaKsiazka_Logo.png' },
          { src: 'ThaiWok_Logo.png' },
          { src: 'Woblink_Logo.png' },
          { src: 'Wolt_Logo.png' },
          { src: 'Wyjotkowy_prezent_Logo.png' },
          { src: 'Zatorland_Logo.png' },
        ],
      },
    ];
  }
  getMobileSlides() {
    return [
      {
        logos: [{ src: '4F_Logo.png' }, { src: 'Answear_Logo.png' }, { src: 'BodyChief_Logo.png' }],
      },
      {
        logos: [{ src: 'Bonito_Logo.png' }, { src: 'Chocholowskie_termy_Logo.png' }, { src: 'CostaCoffee_Logo.png' }],
      },
      {
        logos: [{ src: 'Dajar_Logo.png' }, { src: 'Duka_Logo.png' }, { src: 'Empik_Logo.png' }],
      },
      {
        logos: [{ src: 'FlixBus_Logo.png' }, { src: 'Frisco_Logo.png' }, { src: 'Gerlach_logo_Logo.png' }],
      },
      {
        logos: [{ src: 'Hebe_Logo.png' }, { src: 'LegalneLody_Logo.png' }, { src: 'Lerni_Logo.png' }],
      },
      {
        logos: [{ src: 'L_Occitane_Logo.png' }, { src: 'Martes_Sport_Logo.png' }, { src: 'Medicine_Logo.png' }],
      },
      {
        logos: [{ src: 'Mojcatering_Logo.png' }, { src: 'NewBalance_Logo.png' }, { src: 'NTFY_Logo.png' }],
      },
      {
        logos: [{ src: 'Onet_Premium_Logo.png' }, { src: 'SaladStory_Logo.png' }, { src: 'Sephora_Logo.png' }],
      },
      {
        logos: [{ src: 'Smyk_Logo.png' }, { src: 'Sphinx_Logo.png' }, { src: 'Sportroom_Logo.png' }],
      },
      {
        logos: [{ src: 'Sun&Snow_Logo.png' }, { src: 'Suntago_Logo.png' }, { src: 'SuperMenu_Logo.png' }],
      },
      {
        logos: [{ src: 'TaniaKsiazka_Logo.png' }, { src: 'ThaiWok_Logo.png' }, { src: 'Woblink_Logo.png' }],
      },
      {
        logos: [{ src: 'Wolt_Logo.png' }, { src: 'Wyjotkowy_prezent_Logo.png' }, { src: 'Zatorland_Logo.png' }],
      },
    ];
  }
}
