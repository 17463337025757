import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import { Offer, OfferDetails, OfferVariant } from '../offers/models/offer.interface';
import { ApiFormCart, Cart } from './cart.interface';
import { HttpClient } from '@angular/common/http';
import { OfferService } from '../offers/services/offer.service';

@Injectable({
  providedIn: 'root',
})
export class CartStoreService {
  private cartItems: Cart[] = [];
  private cartSubject = new BehaviorSubject<Cart[]>(this.cartItems);

  private initializedSub = new BehaviorSubject<boolean>(false);
  public initialized$ = this.initializedSub.asObservable();
  private firstInit = true;
  constructor(
    private http: HttpClient,
    private offerService: OfferService
  ) {}

  private updateSubjectAndLocalStorage() {
    this.cartSubject.next(this.cartItems);
    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
  }

  initCartFromLocalStorage() {
    setTimeout(() => {
      const storedCartItemsJson = localStorage.getItem('cartItems');
      if (storedCartItemsJson && this.firstInit) {
        let storedCartItems: Cart[] = JSON.parse(storedCartItemsJson);
        let observables: Observable<OfferDetails>[] = [];

        this.offerService
          .getOffers('paid')
          .pipe(
            map(paidOffers => paidOffers.map(offer => offer.id)),
            tap(offersIdArray => {
              storedCartItems = storedCartItems.filter(offer => offersIdArray.includes(offer.offer_id));
              observables = [...new Set(storedCartItems.map(item => item.offer_id))].map(id =>
                this.http.get<OfferDetails>(`offers/${id}/`)
              );
            }),
            switchMap(() => {
              return forkJoin(observables).pipe(
                map(arr => arr.map(offer => offer.variants.map(variant => variant.id)))
              );
            }),
            finalize(() => {
              this.firstInit = false;
              this.initializedSub.next(true);
            })
          )
          .subscribe(variantIds => {
            storedCartItems = storedCartItems.filter(offer => variantIds.flat().includes(offer.id));
            this.cartItems = storedCartItems;
            this.updateSubjectAndLocalStorage();
          });
      } else {
        this.initializedSub.next(true);
      }
    }, 10);
  }

  addToCart(offer: Offer, variant: OfferVariant): void {
    const existingItem = this.cartItems.find(cartItem => cartItem.id === variant.id);

    if (existingItem) {
      existingItem.quantity++;
    } else {
      //IMPORTANT! both interfaces own 'id' property name, bear in mind the 'id' in Cart interface should come from OfferVariant
      const newItem: Cart = { ...offer, ...variant, quantity: 1, offer_id: offer.id };
      this.cartItems.push(newItem);
    }
    this.updateSubjectAndLocalStorage();
  }

  removeItem(itemId: number) {
    const index = this.cartItems.findIndex(item => item.id === itemId);
    if (index !== -1) {
      this.cartItems.splice(index, 1);
      this.updateSubjectAndLocalStorage();
    }
  }

  getCart(): Observable<Cart[]> {
    return this.cartSubject.asObservable();
  }

  getTotalQuantity(): Observable<number> {
    return this.cartSubject.asObservable().pipe(
      map(items => {
        return items.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);
      })
    );
  }

  updateQuantity(itemId: number, value: number) {
    const existingItem = this.cartItems.find(cartItem => cartItem.id === itemId);
    if (existingItem) {
      existingItem.quantity += value;
      this.updateSubjectAndLocalStorage();
    }
  }

  getTotalPrice(): Observable<number> {
    return this.cartSubject.asObservable().pipe(
      map(items => {
        return items.reduce((totalPrice, item) => totalPrice + item.price * item.quantity, 0);
      })
    );
  }

  clearCart() {
    localStorage.removeItem('cartItems');
    this.cartItems = [];
    this.cartSubject.next(this.cartItems);
  }

  payOrder(data: ApiFormCart): Observable<string> {
    return this.http.post<string>('orders/paid_order_offer/', data);
  }
}
