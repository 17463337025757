import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { routingAnimation } from './core/animations/fade.animations';
import { delay, filter, withLatestFrom } from 'rxjs';
import { AppInitService } from './core/services/app-init.service';
import { UserActivityService } from './core/services/user-activity.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  template: `
    <app-loader *ngIf="isLoading"></app-loader>
    <app-layout-header></app-layout-header>
    <div [@fadeAnimation]="animateRouting">
      <router-outlet></router-outlet>
    </div>
    <app-layout-footer></app-layout-footer>
  `,
  animations: [routingAnimation],
  styles: [],
})
export class AppComponent implements OnInit {
  isLoading = true;
  animateRouting = false;

  constructor(
    private appInitService: AppInitService,
    private router: Router,
    private userActivityService: UserActivityService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initApp();
    this.monitorUserActivity();
    this.routingAnimationHelper();
  }

  initApp() {
    this.appInitService.initialized$.pipe(delay(1500)).subscribe(initialized => {
      this.isLoading = !initialized;
    });
  }

  monitorUserActivity() {
    this.userActivityService
      .startMonitoring()
      .pipe(withLatestFrom(this.authService.isAuthenticated))
      .subscribe(([isActive, isAuthenticated]) => {
        if (!isActive && isAuthenticated) {
          this.authService.logout();
        }
      });
  }

  routingAnimationHelper() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.animateRouting = !this.animateRouting;
    });
  }
}
