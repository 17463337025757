<h2 class="title">Jak skorzystać ze zniżek?</h2>
<div class="icons">
  <div class="icons__item">
    <div class="icons__image"><img src="/assets/images/icons/refinement.svg" alt="" /></div>
    <div class="icons__description"><p>Kliknij "Sprawdzam"</p></div>
  </div>

  <div class="icons__item">
    <div class="icons__image"><img src="/assets/images/icons/diamond.svg" alt="" /></div>
    <div class="icons__description"><p>Wybierz interesującą Cię ofertę</p></div>
  </div>

  <div class="icons__item">
    <div class="icons__image"><img src="/assets/images/icons/supermarket_cart.svg" alt="" /></div>
    <div class="icons__description"><p>Skorzystaj z rabatu u naszego partnera</p></div>
  </div>
</div>
