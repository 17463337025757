<footer>
  <div class="util__centering-flexbox-container">
    <div class="util__image-container">
      <img class="logo-santander" src="/assets/images/brands/logo_santander_white.svg" alt="logo" />
    </div>
    <ul>
      <li>Kontakt</li>
      <li><a class="link" routerLink="forms/contact">Formularz kontaktowy</a></li>
      <li>santander@upbonus.pl</li>
    </ul>
    <ul>
      <li><a class="link" (click)="navigateToRegulations('regulations/platform')">Regulamin</a></li>
      <li>
        <a class="link" (click)="navigateToRegulations('regulations/privacy-policy')"
          >Zasady przetwarzania danych <br />
          osobowych</a
        >
      </li>
    </ul>
    <ul>
      <li>Bonus Systems Polska</li>
      <li>Spółka Akcyjna</li>
      <li>ul. Dywizjonu 303 139/137</li>
      <li>01-470 Warszawa</li>
    </ul>
    <div class="util__image-container">
      <img class="logo-upbonus" src="/assets/images/brands/logo_upbonus_white.png" alt="logo" />
    </div>
  </div>
</footer>
