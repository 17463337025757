import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable, tap } from 'rxjs';
import { ApiToken } from '../models/api-token.interface';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authSubject = new BehaviorSubject<boolean>(false);

  public isAuthenticated = this.authSubject.asObservable().pipe(
    distinctUntilChanged(),
    map(() => this.tokenService.isTokenValid())
  );
  constructor(
    private readonly http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly router: Router
  ) {}

  login(token: string): Observable<ApiToken> {
    void this.router.navigate(['/welcome']);
    this.purgeAuth();
    return this.http
      .post<ApiToken>(`api/auth/login/`, { token })
      .pipe(tap((apiToken: ApiToken) => this.setAuth(apiToken)));
  }

  logout(): void {
    const token = this.tokenService.getToken();
    this.http.post<null>(`api/auth/logout/`, { token }).subscribe();
    this.purgeAuth();
    void this.router.navigate(['/welcome']);
  }

  setAuth(apiToken: ApiToken): void {
    this.tokenService.saveTokenAndExp(apiToken);

    if (this.tokenService.isTokenValid()) {
      this.authSubject.next(true);
    } else {
      this.purgeAuth();
    }
  }

  purgeAuth(): void {
    this.tokenService.destroyToken();
    this.authSubject.next(false);
  }
}
