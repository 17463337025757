import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AdminBanner } from './header.interface';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private http: HttpClient) {}

  getAdminBanner(): Observable<AdminBanner> {
    return this.http.get<AdminBanner>('banners/active_or_default/');
  }
}
