import { Injectable } from '@angular/core';
import { animationDuration } from '../constants/data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilNavigationService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {}

  navigateToPageThatContainsFragment(targetPath: string, anchor?: string): void {
    if (anchor) {
      if (this.router.url.startsWith(`/${targetPath}?fragment`)) {
        this.viewportScroller.scrollToAnchor(anchor);
      } else {
        this.router.navigate([`/${targetPath}`], { queryParams: { fragment: anchor } });
      }
    } else {
      this.router.navigate([`/${targetPath}`]);
    }
  }

  captureFragmentAndScroll(): Observable<Params> {
    return this.activatedRoute.queryParams.pipe(
      tap(queryParams => {
        const anchor = queryParams['fragment'];
        if (anchor) {
          const element = document.getElementById(anchor);
          setTimeout(() => {
            if (element) {
              const elementPosition = element.getBoundingClientRect().top + window.scrollY;
              const offsetPosition = elementPosition - 72;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
            // if (element) element.scrollIntoView({ behavior: 'smooth' });
            // this.viewportScroller.scrollToAnchor(anchor);
          }, animationDuration + 50);
        }
      })
    );
  }
}
