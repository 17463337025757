import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './features/landing-page/landing-page.component';
import { authGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'welcome',
    component: LandingPageComponent,
  },
  {
    path: 'offers',
    loadComponent: () => import('./features/offers/offers.component').then(m => m.OffersComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./features/offers/pages/offers-list/offers-list.component').then(m => m.OffersListComponent),
        canActivate: [authGuard],
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./features/offers/pages/offer-details/offer-details.component').then(m => m.OfferDetailsComponent),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'regulations',
    loadComponent: () => import('./features/regulations/regulations.component').then(m => m.RegulationsComponent),
    children: [
      {
        path: '',
        redirectTo: 'platform',
        pathMatch: 'full',
      },
      {
        path: 'platform',
        loadComponent: () =>
          import('./features/regulations/pages/platform/platform.component').then(m => m.PlatformComponent),
      },
      {
        path: 'privacy-policy',
        loadComponent: () =>
          import('./features/regulations/pages/privacy-policy/privacy-policy.component').then(
            m => m.PrivacyPolicyComponent
          ),
      },
    ],
  },
  {
    path: 'forms',
    loadComponent: () => import('./features/forms/forms.component').then(m => m.FormsComponent),
    children: [
      {
        path: 'contact',
        loadComponent: () => import('./features/forms/pages/contact/contact.component').then(m => m.ContactComponent),
      },
      {
        path: 'partner',
        loadComponent: () => import('./features/forms/pages/partner/partner.component').then(m => m.PartnerComponent),
      },
    ],
  },
  {
    path: 'order-history',
    loadComponent: () => import('./features/order-history/order-history.component').then(m => m.OrderHistoryComponent),
    canActivate: [authGuard],
  },
  {
    path: 'cart',
    loadComponent: () => import('./features/cart/cart.component').then(m => m.CartComponent),
    canActivate: [authGuard],
  },
  {
    path: 'checkout-success',
    loadComponent: () =>
      import('./features/checkout-success/checkout-success.component').then(m => m.CheckoutSuccessComponent),
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: 'welcome',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 72],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
