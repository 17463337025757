import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offer, OfferDetails } from '../../../features/offers/models/offer.interface';
import { Router, RouterLink } from '@angular/router';
import { Cart } from '../../../features/cart/cart.interface';

@Component({
  selector: 'app-offer-card',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
})
export class OfferCardComponent {
  @Input() offer!: Offer | OfferDetails | Cart;

  constructor(private router: Router) {}

  goToDetails(id: number) {
    this.router.navigateByUrl(`/offers/${id}`);
  }
}
