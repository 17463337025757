import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor(private renderer: Renderer2) {}
  ngOnInit(): void {
    this.renderer.addClass(document.body, 'disable-scroll');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'disable-scroll');
  }
}
