import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Subscription } from 'rxjs';
import { animationDuration } from '../../../../core/constants/data';
import { PartnersService } from './partners.service';

const animation = { duration: 50000, easing: (t: any) => t };
@Component({
  selector: 'app-partners',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './partners.component.html',
  styleUrls: ['../../../../../../node_modules/keen-slider/keen-slider.min.css', './partners.component.scss'],
})
export class PartnersComponent implements AfterViewInit, OnDestroy {
  private breakpointSubscriptions: Subscription[] = [];
  slidesDesktop = this.partnerService.getDesktopSlides();
  slidesTablet = this.partnerService.getTabletSlides();
  slidesMobile = this.partnerService.getMobileSlides();

  //important to test it on mobiles and not DOM environment
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  slider!: KeenSliderInstance | null;
  isXSmallScreen$ = this.breakpointObserver.observe(Breakpoints.XSmall).pipe(map(result => result.matches));
  isSmallScreen$ = this.breakpointObserver.observe(Breakpoints.Small).pipe(map(result => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private partnerService: PartnersService
  ) {}

  ngAfterViewInit() {
    this.subscribeToBreakPoints();
    this.buildSlider();
  }

  buildSlider() {
    if (!this.slider) {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        loop: true,
        renderMode: 'performance',
        drag: true,
        created(s) {
          s.moveToIdx(5, true, animation);
        },
        updated(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
        animationEnded(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
      });
    }
  }

  subscribeToBreakPoints() {
    const xSmallSubscription = this.isXSmallScreen$.subscribe(matches => {
      if (matches) {
        this.refreshSlider();
      }
    });
    this.breakpointSubscriptions.push(xSmallSubscription);

    const smallSubscription = this.isSmallScreen$.subscribe(matches => {
      if (matches) {
        this.refreshSlider();
      }
    });
    this.breakpointSubscriptions.push(smallSubscription);
  }

  refreshSlider() {
    if (this.slider) this.slider.destroy();
    this.slider = null;
    this.buildSlider();
  }

  ngOnDestroy() {
    this.breakpointSubscriptions.forEach(subscription => subscription.unsubscribe());
    setTimeout(() => {
      if (this.slider) this.slider.destroy();
    }, animationDuration);
  }
}
